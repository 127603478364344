@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-2000 {
    animation-delay: 2s;
  }
  .animation-delay-4000 {
    animation-delay: 4s;
  }
}

/* Ensure elements are initially hidden but taking up space */
.opacity-0 {
  opacity: 0;
}

/* Transition class to be added when elements are in the viewport */
.visible {
  opacity: 1;
}

html {
  scroll-behavior: smooth;
}
